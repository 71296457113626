import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  fluidFontSize,
  minBreakpointQuery,
} from '../styles';
import { Link } from './ui';

const StyledResourceCard = styled.article`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  transform: translateZ(0);
`;

const StyledImage = styled(GatsbyImage)``;

const StyledContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 25px 30px;
  background: linear-gradient(
    ${standardColours.transparentBlack(0.5)},
    ${brandColours.primary}
  );

  ${minBreakpointQuery.small`
    padding: 30px 35px;
  `}

  ${minBreakpointQuery.medium`
    padding: 35px 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 40px 45px;
  `}
`;

const StyledMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: auto;
`;

const StyledTag = styled.span`
  display: inline-block;
  padding: 5px 12px;
  color: ${brandColours.septenary};
  font-weight: ${fontWeights.bold};
  ${fontSize(12)};
  border-radius: 14px;

  ${({ type }) => {
    if (type === 'DatoCmsCaseStudy') {
      return css`
        background-color: ${brandColours.primary};
      `;
    } else if (type === 'DatoCmsNews') {
      return css`
        background-color: ${brandColours.quinary};
      `;
    } else if (type === 'DatoCmsBlog') {
      return css`
        background-color: ${brandColours.secondary};
      `;
    } else if (type === 'DatoCmsReport') {
      return css`
        background-color: ${brandColours.octonary};
      `;
    }
  }}

  ${minBreakpointQuery.medium`
    ${fontSize(14)};
  `}
`;

const StyledDate = styled.span`
  color: ${brandColours.septenary};
  font-weight: ${fontWeights.bold};
  ${fontSize(12)};

  ${minBreakpointQuery.medium`
    ${fontSize(14)};
  `}
`;

const StyledHeading = styled.h3`
  color: ${brandColours.septenary};
  font-family: ${brandFonts.secondary};
  ${fluidFontSize(
    '20px',
    '30px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledLink = styled(Link)`
  margin-top: 12px;
  color: ${brandColours.quaternary};
  font-weight: ${fontWeights.bold};
  ${fontSize(12)};
  text-transform: uppercase;

  ${minBreakpointQuery.small`
    margin-top: 16px;
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}

  &:after {
    content: '→';
    margin-left: 10px;
    ${fontSize(16)};

    ${minBreakpointQuery.medium`
      ${fontSize(18)};
    `}
  }
`;

const ResourceCard = ({
  image,
  type,
  publishedDate,
  heading,
  slug,
  ...props
}) => {
  const getTypeName = type => {
    let typeSlug = '';

    if (type === 'DatoCmsCaseStudy') {
      typeSlug = 'Case Study';
    } else if (type === 'DatoCmsNews') {
      typeSlug = 'News Post';
    } else if (type === 'DatoCmsBlog') {
      typeSlug = 'Blog Post';
    } else if (type === 'DatoCmsReport') {
      typeSlug = 'Report';
    }

    return typeSlug;
  };

  return (
    <StyledResourceCard {...props}>
      <StyledImage image={image.gatsbyImageData} alt={image.alt} />
      <StyledContent>
        <StyledMeta>
          <StyledTag type={type}>{getTypeName(type)}</StyledTag>
          {publishedDate && <StyledDate>{publishedDate}</StyledDate>}
        </StyledMeta>
        <StyledHeading>{heading}</StyledHeading>
        <StyledLink to={slug}>Read {getTypeName(type)}</StyledLink>
      </StyledContent>
    </StyledResourceCard>
  );
};

export default ResourceCard;

export const ResourceCardFragments = graphql`
  fragment ResourceCardImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 460, height: 570)
    alt
  }

  fragment CaseStudyResourceCardFragment on DatoCmsCaseStudy {
    id
    title
    slug
    banner {
      image {
        ...ResourceCardImageFragment
      }
    }
    internal {
      type
    }
  }

  fragment NewsResourceCardFragment on DatoCmsNews {
    id
    title
    slug
    banner {
      image {
        ...ResourceCardImageFragment
      }
    }
    publishedDate(formatString: "D MMM YYYY")
    meta {
      firstPublishedAt(formatString: "D MMM YYYY")
    }
    internal {
      type
    }
  }

  fragment BlogResourceCardFragment on DatoCmsBlog {
    id
    title
    slug
    banner {
      image {
        ...ResourceCardImageFragment
      }
    }
    publishedDate(formatString: "D MMM YYYY")
    meta {
      firstPublishedAt(formatString: "D MMM YYYY")
    }
    internal {
      type
    }
  }
`;
