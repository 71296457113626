import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  visuallyHidden,
} from '../styles';
import { Container } from './ui';
import ResourceCard from './ResourceCard';
import { buildUrl } from '../utils';

const StyledResourceListing = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
    row-gap: 25px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
  `}
`;

const StyledItem = styled(ResourceCard)`
  ${maxBreakpointQuery.tsmall`
    max-height: 300px;
  `}
`;

const ResourceListing = ({ heading, items, archiveSlug, locale }) =>
  items.length > 0 && (
    <StyledResourceListing>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        <StyledItems>
          {items.map(item => {
            if (item.slug) {
              const {
                id,
                banner: { image },
                internal: { type },
                publishedDate,
                meta,
                title,
                slug,
              } = item;

              return (
                <StyledItem
                  key={id}
                  image={image}
                  type={type}
                  publishedDate={
                    publishedDate || (meta && meta.firstPublishedAt)
                  }
                  heading={title}
                  slug={buildUrl(`${archiveSlug}/${slug}`, {
                    locale: locale,
                  })}
                />
              );
            } else {
              return '';
            }
          })}
        </StyledItems>
      </Container>
    </StyledResourceListing>
  );

export default ResourceListing;
